import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Appartment1_Img1 from '../../assets/images/listing/listing1/1.webp';
import Appartment1_Img2 from '../../assets/images/listing/listing1/2.webp';
import Appartment1_Img3 from '../../assets/images/listing/listing1/3.webp';
import Appartment1_Img4 from '../../assets/images/listing/listing1/4.webp';
import Appartment1_Img5 from '../../assets/images/listing/listing1/5.webp';
import Appartment1_Img6 from '../../assets/images/listing/listing1/6.webp';
import Appartment1_Img7 from '../../assets/images/listing/listing1/7.webp';
import Appartment1_Img8 from '../../assets/images/listing/listing1/8.webp';
import Appartment1_Img9 from '../../assets/images/listing/listing1/9.webp';
import Appartment1_Img10 from '../../assets/images/listing/listing1/10.webp';
import Appartment1_Img11 from '../../assets/images/listing/listing1/11.webp';
import Appartment1_Img12 from '../../assets/images/listing/listing1/12.webp';
import Appartment1_Img13 from '../../assets/images/listing/listing1/13.webp';
import Appartment1_Img14 from '../../assets/images/listing/listing1/14.webp';
import Appartment1_Img15 from '../../assets/images/listing/listing1/15.webp';
import Appartment1_Img16 from '../../assets/images/listing/listing1/16.webp';
import Appartment1_Img17 from '../../assets/images/listing/listing1/17.webp';
import Appartment1_Img18 from '../../assets/images/listing/listing1/18.webp';
import Appartment1_Img19 from '../../assets/images/listing/listing1/19.webp';

import Appartment2_Img1 from '../../assets/images/listing/listing5/1.webp';
import Appartment2_Img2 from '../../assets/images/listing/listing5/2.webp';
import Appartment2_Img3 from '../../assets/images/listing/listing5/3.webp';
import Appartment2_Img4 from '../../assets/images/listing/listing5/4.webp';
import Appartment2_Img5 from '../../assets/images/listing/listing5/5.webp';
import Appartment2_Img6 from '../../assets/images/listing/listing5/6.webp';
import Appartment2_Img7 from '../../assets/images/listing/listing5/7.webp';
import Appartment2_Img8 from '../../assets/images/listing/listing5/8.webp';
import Appartment2_Img9 from '../../assets/images/listing/listing5/9.webp';
import Appartment2_Img10 from '../../assets/images/listing/listing5/10.webp';

import Appartment3_Img1 from '../../assets/images/listing/listing6/1.webp';
import Appartment3_Img2 from '../../assets/images/listing/listing6/2.webp';
import Appartment3_Img3 from '../../assets/images/listing/listing6/3.webp';
import Appartment3_Img4 from '../../assets/images/listing/listing6/4.webp';
import Appartment3_Img5 from '../../assets/images/listing/listing6/5.webp';
import Appartment3_Img6 from '../../assets/images/listing/listing6/6.webp';
import Appartment3_Img7 from '../../assets/images/listing/listing6/7.webp';
import Appartment3_Img8 from '../../assets/images/listing/listing6/8.webp';
import Appartment3_Img9 from '../../assets/images/listing/listing6/9.webp';
import Appartment3_Img10 from '../../assets/images/listing/listing6/10.webp';
import Appartment3_Img11 from '../../assets/images/listing/listing6/11.webp';
import Appartment3_Img12 from '../../assets/images/listing/listing6/12.webp';
import Appartment3_Img13 from '../../assets/images/listing/listing6/13.webp';
import Appartment3_Img14 from '../../assets/images/listing/listing6/14.webp';
import Appartment3_Img15 from '../../assets/images/listing/listing6/15.webp';
import Appartment3_Img16 from '../../assets/images/listing/listing6/16.webp';
import Appartment3_Img17 from '../../assets/images/listing/listing6/17.webp';
import Appartment3_Img18 from '../../assets/images/listing/listing6/18.webp';
import Appartment3_Img19 from '../../assets/images/listing/listing6/19.webp';

import Appartment4_Img1 from '../../assets/images/listing/listing7/1.webp';
import Appartment4_Img2 from '../../assets/images/listing/listing7/2.webp';
import Appartment4_Img3 from '../../assets/images/listing/listing7/3.webp';
import Appartment4_Img4 from '../../assets/images/listing/listing7/4.webp';
import Appartment4_Img5 from '../../assets/images/listing/listing7/5.webp';
import Appartment4_Img6 from '../../assets/images/listing/listing7/6.webp';
import Appartment4_Img7 from '../../assets/images/listing/listing7/7.webp';
import Appartment4_Img8 from '../../assets/images/listing/listing7/8.webp';
import Appartment4_Img9 from '../../assets/images/listing/listing7/9.webp';
import Appartment4_Img10 from '../../assets/images/listing/listing7/10.webp';
import Appartment4_Img11 from '../../assets/images/listing/listing7/11.webp';
import Appartment4_Img12 from '../../assets/images/listing/listing7/12.webp';
import Appartment4_Img13 from '../../assets/images/listing/listing7/13.webp';
import Appartment4_Img14 from '../../assets/images/listing/listing7/14.webp';
import Appartment4_Img15 from '../../assets/images/listing/listing7/15.webp';
import Appartment4_Img16 from '../../assets/images/listing/listing7/16.webp';
import Appartment4_Img17 from '../../assets/images/listing/listing7/17.webp';
import Appartment4_Img18 from '../../assets/images/listing/listing7/18.webp';
import Appartment4_Img19 from '../../assets/images/listing/listing7/19.webp';
import Appartment4_Img20 from '../../assets/images/listing/listing7/20.webp';
import Appartment4_Img21 from '../../assets/images/listing/listing7/21.webp';
import Appartment4_Img22 from '../../assets/images/listing/listing7/22.webp';
import Appartment4_Img23 from '../../assets/images/listing/listing7/23.webp';
import Appartment4_Img24 from '../../assets/images/listing/listing7/24.webp';
import Appartment4_Img25 from '../../assets/images/listing/listing7/25.webp';
import Appartment4_Img26 from '../../assets/images/listing/listing7/26.webp';
import Appartment4_Img27 from '../../assets/images/listing/listing7/27.webp';
import Appartment4_Img28 from '../../assets/images/listing/listing7/28.webp';
import Appartment4_Img29 from '../../assets/images/listing/listing7/29.webp';
import Appartment4_Img30 from '../../assets/images/listing/listing7/30.webp';
import Appartment4_Img31 from '../../assets/images/listing/listing7/31.webp';
import Appartment4_Img32 from '../../assets/images/listing/listing7/32.webp';
import Appartment4_Img33 from '../../assets/images/listing/listing7/33.webp';
import Appartment4_Img34 from '../../assets/images/listing/listing7/34.webp';
import Appartment4_Img35 from '../../assets/images/listing/listing7/35.webp';

import Appartment5_Img1 from '../../assets/images/listing/listing8/1.webp';
import Appartment5_Img2 from '../../assets/images/listing/listing8/2.webp';
import Appartment5_Img3 from '../../assets/images/listing/listing8/3.webp';
import Appartment5_Img4 from '../../assets/images/listing/listing8/4.webp';
import Appartment5_Img5 from '../../assets/images/listing/listing8/5.webp';
import Appartment5_Img6 from '../../assets/images/listing/listing8/6.webp';
import Appartment5_Img7 from '../../assets/images/listing/listing8/7.webp';
import Appartment5_Img8 from '../../assets/images/listing/listing8/8.webp';
import Appartment5_Img9 from '../../assets/images/listing/listing8/9.webp';
import Appartment5_Img10 from '../../assets/images/listing/listing8/10.webp';
import Appartment5_Img11 from '../../assets/images/listing/listing8/11.webp';
import Appartment5_Img12 from '../../assets/images/listing/listing8/12.webp';
import Appartment5_Img13 from '../../assets/images/listing/listing8/13.webp';
import Appartment5_Img14 from '../../assets/images/listing/listing8/14.webp';
import Appartment5_Img15 from '../../assets/images/listing/listing8/15.webp';
import Appartment5_Img16 from '../../assets/images/listing/listing8/16.webp';
import Appartment5_Img17 from '../../assets/images/listing/listing8/17.webp';
import Appartment5_Img18 from '../../assets/images/listing/listing8/18.webp';
import Appartment5_Img19 from '../../assets/images/listing/listing8/19.webp';
import Appartment5_Img20 from '../../assets/images/listing/listing8/20.webp';
import Appartment5_Img21 from '../../assets/images/listing/listing8/21.webp';
import Appartment5_Img22 from '../../assets/images/listing/listing8/22.webp';
import Appartment5_Img23 from '../../assets/images/listing/listing8/23.webp';
import Appartment5_Img24 from '../../assets/images/listing/listing8/24.webp';
import Appartment5_Img25 from '../../assets/images/listing/listing8/25.webp';
import Appartment5_Img26 from '../../assets/images/listing/listing8/26.webp';
import Appartment5_Img27 from '../../assets/images/listing/listing8/27.webp';
import Appartment5_Img28 from '../../assets/images/listing/listing8/28.webp';
import Appartment5_Img29 from '../../assets/images/listing/listing8/29.webp';
import Appartment5_Img30 from '../../assets/images/listing/listing8/30.webp';
import Appartment5_Img31 from '../../assets/images/listing/listing8/31.webp';
import Appartment5_Img32 from '../../assets/images/listing/listing8/32.webp';
import Appartment5_Img33 from '../../assets/images/listing/listing8/33.webp';
import Appartment5_Img34 from '../../assets/images/listing/listing8/34.webp';
import Appartment5_Img35 from '../../assets/images/listing/listing8/35.webp';
import Appartment5_Img36 from '../../assets/images/listing/listing8/36.webp';
import Appartment5_Img37 from '../../assets/images/listing/listing8/37.webp';
import Appartment5_Img38 from '../../assets/images/listing/listing8/38.webp';
import Appartment5_Img39 from '../../assets/images/listing/listing8/39.webp';
import Appartment5_Img40 from '../../assets/images/listing/listing8/40.webp';
import Appartment5_Img41 from '../../assets/images/listing/listing8/41.webp';
import Appartment5_Img42 from '../../assets/images/listing/listing8/42.webp';
import Appartment5_Img43 from '../../assets/images/listing/listing8/43.webp';
import Appartment5_Img44 from '../../assets/images/listing/listing8/44.webp';
import Appartment5_Img45 from '../../assets/images/listing/listing8/45.webp';
import Appartment5_Img46 from '../../assets/images/listing/listing8/46.webp';
import Appartment5_Img47 from '../../assets/images/listing/listing8/47.webp';
import Appartment5_Img48 from '../../assets/images/listing/listing8/48.webp';
import Appartment5_Img49 from '../../assets/images/listing/listing8/49.webp';
import Appartment5_Img50 from '../../assets/images/listing/listing8/50.webp';

import Appartment6_Img1 from '../../assets/images/listing/listing10/1.webp';
import Appartment6_Img2 from '../../assets/images/listing/listing10/2.webp';
import Appartment6_Img3 from '../../assets/images/listing/listing10/3.webp';
import Appartment6_Img4 from '../../assets/images/listing/listing10/4.webp';
import Appartment6_Img5 from '../../assets/images/listing/listing10/5.webp';
import Appartment6_Img6 from '../../assets/images/listing/listing10/6.webp';
import Appartment6_Img7 from '../../assets/images/listing/listing10/7.webp';
import Appartment6_Img8 from '../../assets/images/listing/listing10/8.webp';
import Appartment6_Img9 from '../../assets/images/listing/listing10/9.webp';
import Appartment6_Img10 from '../../assets/images/listing/listing10/10.webp';
import Appartment6_Img11 from '../../assets/images/listing/listing10/11.webp';
import Appartment6_Img12 from '../../assets/images/listing/listing10/12.webp';
import Appartment6_Img13 from '../../assets/images/listing/listing10/13.webp';
import Appartment6_Img14 from '../../assets/images/listing/listing10/14.webp';
import Appartment6_Img15 from '../../assets/images/listing/listing10/15.webp';
import Appartment6_Img16 from '../../assets/images/listing/listing10/16.webp';
import Appartment6_Img17 from '../../assets/images/listing/listing10/17.webp';
import Appartment6_Img18 from '../../assets/images/listing/listing10/18.webp';
import Appartment6_Img19 from '../../assets/images/listing/listing10/19.webp';
import Appartment6_Img20 from '../../assets/images/listing/listing10/20.webp';
import Appartment6_Img21 from '../../assets/images/listing/listing10/21.webp';
import Appartment6_Img22 from '../../assets/images/listing/listing10/22.webp';
import Appartment6_Img23 from '../../assets/images/listing/listing10/23.webp';

import Appartment7_Img1 from '../../assets/images/listing/listing11/1.webp';
import Appartment7_Img2 from '../../assets/images/listing/listing11/2.webp';
import Appartment7_Img3 from '../../assets/images/listing/listing11/3.webp';
import Appartment7_Img4 from '../../assets/images/listing/listing11/4.webp';
import Appartment7_Img5 from '../../assets/images/listing/listing11/5.webp';
import Appartment7_Img6 from '../../assets/images/listing/listing11/6.webp';
import Appartment7_Img7 from '../../assets/images/listing/listing11/7.webp';
import Appartment7_Img8 from '../../assets/images/listing/listing11/8.webp';
import Appartment7_Img9 from '../../assets/images/listing/listing11/9.webp';
import Appartment7_Img10 from '../../assets/images/listing/listing11/10.webp';
import Appartment7_Img11 from '../../assets/images/listing/listing11/11.webp';
import Appartment7_Img12 from '../../assets/images/listing/listing11/12.webp';
import Appartment7_Img13 from '../../assets/images/listing/listing11/13.webp';
import Appartment7_Img14 from '../../assets/images/listing/listing11/14.webp';
import Appartment7_Img15 from '../../assets/images/listing/listing11/15.webp';
import Appartment7_Img16 from '../../assets/images/listing/listing11/16.webp';
import Appartment7_Img17 from '../../assets/images/listing/listing11/17.webp';
import Appartment7_Img18 from '../../assets/images/listing/listing11/18.webp';
import Appartment7_Img19 from '../../assets/images/listing/listing11/19.webp';
import Appartment7_Img20 from '../../assets/images/listing/listing11/20.webp';
import Appartment7_Img21 from '../../assets/images/listing/listing11/21.webp';
import Appartment7_Img22 from '../../assets/images/listing/listing11/22.webp';
import Appartment7_Img23 from '../../assets/images/listing/listing11/23.webp';
import Appartment7_Img24 from '../../assets/images/listing/listing11/24.webp';
import Appartment7_Img25 from '../../assets/images/listing/listing11/25.webp';

import Appartment8_Img1 from '../../assets/images/listing/listing12/1.webp';
import Appartment8_Img2 from '../../assets/images/listing/listing12/2.webp';
import Appartment8_Img3 from '../../assets/images/listing/listing12/3.webp';
import Appartment8_Img4 from '../../assets/images/listing/listing12/4.webp';
import Appartment8_Img5 from '../../assets/images/listing/listing12/5.webp';
import Appartment8_Img6 from '../../assets/images/listing/listing12/6.webp';
import Appartment8_Img7 from '../../assets/images/listing/listing12/7.webp';
import Appartment8_Img8 from '../../assets/images/listing/listing12/8.webp';
import Appartment8_Img9 from '../../assets/images/listing/listing12/9.webp';
import Appartment8_Img10 from '../../assets/images/listing/listing12/10.webp';
import Appartment8_Img11 from '../../assets/images/listing/listing12/11.webp';
import Appartment8_Img12 from '../../assets/images/listing/listing12/12.webp';
import Appartment8_Img13 from '../../assets/images/listing/listing12/13.webp';
import Appartment8_Img14 from '../../assets/images/listing/listing12/14.webp';
import Appartment8_Img15 from '../../assets/images/listing/listing12/15.webp';
import Appartment8_Img16 from '../../assets/images/listing/listing12/16.webp';
import Appartment8_Img17 from '../../assets/images/listing/listing12/17.webp';
import Appartment8_Img18 from '../../assets/images/listing/listing12/18.webp';
import Appartment8_Img19 from '../../assets/images/listing/listing12/19.webp';
import Appartment8_Img20 from '../../assets/images/listing/listing12/20.webp';
import Appartment8_Img21 from '../../assets/images/listing/listing12/21.webp';
import Appartment8_Img22 from '../../assets/images/listing/listing12/22.webp';
import Appartment8_Img23 from '../../assets/images/listing/listing12/23.webp';

import SingleListing from './SingleListing';

const Apartments = () => {

  return (
    <>
      <SingleListing
      Img1={Appartment1_Img1}
      Img2={Appartment1_Img2}
      Img3={Appartment1_Img3}
      Img4={Appartment1_Img4}
      Img5={Appartment1_Img5}
      Img6={Appartment1_Img6}
      Img7={Appartment1_Img7}
      Img8={Appartment1_Img8}
      Img9={Appartment1_Img9}
      Img10={Appartment1_Img10}
      Img11={Appartment1_Img11}
      Img12={Appartment1_Img12}
      Img13={Appartment1_Img13}
      Img14={Appartment1_Img14}
      Img15={Appartment1_Img15}
      Img16={Appartment1_Img16}
      Img17={Appartment1_Img17}
      Img18={Appartment1_Img18}
      Img19={Appartment1_Img19}
      Link="/apartment-for-sale-sharjah-aljada-naseej-district-il-teatro-residences-2"
      Title="Apartment in Il Teatro Residences 2, Naseej District"
      Address="Sharjah"
      Category="Apartments"
      Beds="1"
      Baths="2"
      SqFt="749 sqft / 70 sqm"
      Price="1,070,000"
      />       
      
      <SingleListing
      Img1={Appartment2_Img1}
      Img2={Appartment2_Img2}
      Img3={Appartment2_Img3}
      Img4={Appartment2_Img4}
      Img5={Appartment2_Img5}
      Img6={Appartment2_Img6}
      Img7={Appartment2_Img7}
      Img8={Appartment2_Img8}
      Img9={Appartment2_Img9}
      Img10={Appartment2_Img10}
      Link="/apartment-for-sale-dubai-dubai-residence-complex-ag-7even"
      Title="Apartment in Ag 7even, Dubai Residence Complex"
      Address="Dubai"
      Category="Apartments"
      Beds="Studio"
      Baths="1"
      SqFt="371 sqft / 34 sqm"
      Price="449,000"
      />  

       <SingleListing
      Img1={Appartment3_Img1}
      Img2={Appartment3_Img2}
      Img3={Appartment3_Img3}
      Img4={Appartment3_Img4}
      Img5={Appartment3_Img5}
      Img6={Appartment3_Img6}
      Img7={Appartment3_Img7}
      Img8={Appartment3_Img8}
      Img9={Appartment3_Img9}
      Img10={Appartment3_Img10}
      Img11={Appartment3_Img11}
      Img12={Appartment3_Img12}
      Img13={Appartment3_Img13}
      Img14={Appartment3_Img14}
      Img15={Appartment3_Img15}
      Img16={Appartment3_Img16}
      Img17={Appartment3_Img17}
      Img18={Appartment3_Img18}
      Img19={Appartment3_Img19}
      Link="/apartment-for-sale-sharjah-aljada-nesba"
      Title={
        <> 
        Apartment in Nesba, <br /> Aljada
        </>
      }
      Address="Sharjah"
      Category="Apartments"
      Beds="2"
      Baths="3"
      SqFt="1,194 sqft / 111 sqm"
      Price="1,484,000"
      />   
      
      <SingleListing
      Img1={Appartment4_Img1}
      Img2={Appartment4_Img2}
      Img3={Appartment4_Img3}
      Img4={Appartment4_Img4}
      Img5={Appartment4_Img5}
      Img6={Appartment4_Img6}
      Img7={Appartment4_Img7}
      Img8={Appartment4_Img8}
      Img9={Appartment4_Img9}
      Img10={Appartment4_Img10}
      Img11={Appartment4_Img11}
      Img12={Appartment4_Img12}
      Img13={Appartment4_Img13}
      Img14={Appartment4_Img14}
      Img15={Appartment4_Img15}
      Img16={Appartment4_Img16}
      Img17={Appartment4_Img17}
      Img18={Appartment4_Img18}
      Img19={Appartment4_Img19}
      Img20={Appartment4_Img20}
      Img21={Appartment4_Img21}
      Img22={Appartment4_Img22}
      Img23={Appartment4_Img23}
      Img24={Appartment4_Img24}
      Img25={Appartment4_Img25}
      Img26={Appartment4_Img26}
      Img27={Appartment4_Img27}
      Img28={Appartment4_Img28}
      Img29={Appartment4_Img29}
      Img30={Appartment4_Img30}
      Img31={Appartment4_Img31}
      Img32={Appartment4_Img32}
      Img33={Appartment4_Img33}
      Img34={Appartment4_Img34}
      Img35={Appartment4_Img35}
      Link="/apartment-for-sale-dubai-al-furjan-primero-residences"
      Title="Apartment in Primero Residences, Al Furjan"
      Address="Dubai"
      Category="Apartments"
      Beds="1"
      Baths="2"
      SqFt="697 sqft / 65 sqm"
      Price="980,000"
      />               
      
      <SingleListing
      Img1={Appartment5_Img1}
      Img2={Appartment5_Img2}
      Img3={Appartment5_Img3}
      Img4={Appartment5_Img4}
      Img5={Appartment5_Img5}
      Img6={Appartment5_Img6}
      Img7={Appartment5_Img7}
      Img8={Appartment5_Img8}
      Img9={Appartment5_Img9}
      Img10={Appartment5_Img10}
      Img11={Appartment5_Img11}
      Img12={Appartment5_Img12}
      Img13={Appartment5_Img13}
      Img14={Appartment5_Img14}
      Img15={Appartment5_Img15}
      Img16={Appartment5_Img16}
      Img17={Appartment5_Img17}
      Img18={Appartment5_Img18}
      Img19={Appartment5_Img19}
      Img20={Appartment5_Img20}
      Img21={Appartment5_Img21}
      Img22={Appartment5_Img22}
      Img23={Appartment5_Img23}
      Img24={Appartment5_Img24}
      Img25={Appartment5_Img25}
      Img26={Appartment5_Img26}
      Img27={Appartment5_Img27}
      Img28={Appartment5_Img28}
      Img29={Appartment5_Img29}
      Img30={Appartment5_Img30}
      Img31={Appartment5_Img31}
      Img32={Appartment5_Img32}
      Img33={Appartment5_Img33}
      Img34={Appartment5_Img34}
      Img35={Appartment5_Img35}
      Img36={Appartment5_Img36}
      Img37={Appartment5_Img37}
      Img38={Appartment5_Img38}
      Img39={Appartment5_Img39}
      Img40={Appartment5_Img40}
      Img41={Appartment5_Img41}
      Img42={Appartment5_Img42}
      Img43={Appartment5_Img43}
      Img44={Appartment5_Img44}
      Img45={Appartment5_Img45}
      Img46={Appartment5_Img46}
      Img47={Appartment5_Img47}
      Img48={Appartment5_Img48}
      Img49={Appartment5_Img49}
      Img50={Appartment5_Img50}
      Link="/apartment-for-sale-dubai-al-furjan-pearlz-by-danube"
      Title="Apartment in Pearlz By Danube, Al Furjan"
      Address="Dubai"
      Category="Apartments"
      Beds="2"
      Baths="2"
      SqFt="1,085 sqft / 101 sqm"
      Price="1,599,999"
      />                    
      
      <SingleListing
      Img1={Appartment6_Img1}
      Img2={Appartment6_Img2}
      Img3={Appartment6_Img3}
      Img4={Appartment6_Img4}
      Img5={Appartment6_Img5}
      Img6={Appartment6_Img6}
      Img7={Appartment6_Img7}
      Img8={Appartment6_Img8}
      Img9={Appartment6_Img9}
      Img10={Appartment6_Img10}
      Img11={Appartment6_Img11}
      Img12={Appartment6_Img12}
      Img13={Appartment6_Img13}
      Img14={Appartment6_Img14}
      Img15={Appartment6_Img15}
      Img16={Appartment6_Img16}
      Img17={Appartment6_Img17}
      Img18={Appartment6_Img18}
      Img19={Appartment6_Img19}
      Img20={Appartment6_Img20}
      Img21={Appartment6_Img21}
      Img22={Appartment6_Img22}
      Img23={Appartment6_Img23}
      Link="/apartment-for-sale-dubai-dubai-science-park-vincitore-aqua-dimore"
      Title="Apartment in Vincitore Aqua Dimore, Dubai Science Park"
      Address="Dubai"
      Category="Apartments"
      Beds="Studio"
      Baths="1"
      SqFt="468 sqft / 43 sqm"
      Price="789,000"
      />     
      
      <SingleListing
      Img1={Appartment7_Img1}
      Img2={Appartment7_Img2}
      Img3={Appartment7_Img3}
      Img4={Appartment7_Img4}
      Img5={Appartment7_Img5}
      Img6={Appartment7_Img6}
      Img7={Appartment7_Img7}
      Img8={Appartment7_Img8}
      Img9={Appartment7_Img9}
      Img10={Appartment7_Img10}
      Img11={Appartment7_Img11}
      Img12={Appartment7_Img12}
      Img13={Appartment7_Img13}
      Img14={Appartment7_Img14}
      Img15={Appartment7_Img15}
      Img16={Appartment7_Img16}
      Img17={Appartment7_Img17}
      Img18={Appartment7_Img18}
      Img19={Appartment7_Img19}
      Img20={Appartment7_Img20}
      Img21={Appartment7_Img21}
      Img22={Appartment7_Img22}
      Img23={Appartment7_Img23}
      Img24={Appartment7_Img24}
      Img25={Appartment7_Img25}
      Link="/apartment-for-sale-dubai-dubai-south-dubai-world-central-south-living"
      Title="Apartment in South Living, Dubai South"
      Address="Dubai"
      Category="Apartments"
      Beds="3"
      Baths="4"
      SqFt="1,669 sqft / 155 sqm"
      Price="1,938,496"
      />                     
      
      <SingleListing
      Img1={Appartment8_Img1}
      Img2={Appartment8_Img2}
      Img3={Appartment8_Img3}
      Img4={Appartment8_Img4}
      Img5={Appartment8_Img5}
      Img6={Appartment8_Img6}
      Img7={Appartment8_Img7}
      Img8={Appartment8_Img8}
      Img9={Appartment8_Img9}
      Img10={Appartment8_Img10}
      Img11={Appartment8_Img11}
      Img12={Appartment8_Img12}
      Img13={Appartment8_Img13}
      Img14={Appartment8_Img14}
      Img15={Appartment8_Img15}
      Img16={Appartment8_Img16}
      Img17={Appartment8_Img17}
      Img18={Appartment8_Img18}
      Img19={Appartment8_Img19}
      Img20={Appartment8_Img20}
      Img21={Appartment8_Img21}
      Img22={Appartment8_Img22}
      Img23={Appartment8_Img23}
      Link="/apartment-for-sale-dubai-dubai-science-park-vincitore-aqua-flora"
      Title="Apartment in Vincitore Aqua Flora, Dubai Science Park"
      Address="Dubai"
      Category="Apartments"
      Beds="Studio"
      Baths="1"
      SqFt="457 sqft / 42 sqm"
      Price="795,000"
      />     
    </>
  );
}

export default Apartments;
